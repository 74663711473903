import { ReducerContext } from "./reducer/reducer.js"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { inputLength, inputSpaces } from "../components/functions/functions.js";
import { db } from "../App.js"
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

/* show a form to create a new user in the database */
const Register = () => {
    const [, dispatch] = useContext(ReducerContext)
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [mail, setMail] = useState("");
    const [birthDate, setBirthDate] = useState("")
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState("")

    useEffect(() => {
        dispatch({type:"PAGE", number:0})
    },[dispatch])    

    useEffect(() => {
        let btn = document.getElementById("btn_register")
        if (loading === true) {
            btn.setAttribute("disabled", "")
        }else if (loading === false) {
            btn.removeAttribute("disabled")
        }
    },[loading])

    const submit = (e) => {
        e.preventDefault();

        const data = {
            firstName,
            lastName,
            mail,
            birthDate,
            password
        };

        // we check if the informations are empty, with spaces-only or too long
        if (inputSpaces(firstName) || inputSpaces(lastName) || inputSpaces(password) || inputSpaces(mail)) {
            window.alert("Vos identifiants doivent contenir des caractères")
        } else if (inputLength(firstName, 63) || inputLength(lastName, 63) || inputLength(mail) || inputLength(password)) {
            window.alert("Vos identifants sont trop long. Un prénom et un nom de famille peuvent contenir au maximum 63 caractères, vous pouvez utiliser un pseudonyme. Pour le mail et le mot de passe, la limite est de 255 caractères.")
        }else {
            const auth = getAuth();
            createUserWithEmailAndPassword(auth, data.mail, data.password)
            .then((userCredential) => {      
                const userId = userCredential.user.uid;
                const unsubscribe = onSnapshot(doc(db, 'users', userId), (user) => {
                    console.log("we get in")
                    if (!user.exists()) {
                        console.log("return")
                        return;
                    }
                    setLoading(true)
                    // mettre a jour le user
                    const updateUserPromise = updateDoc(doc(db, 'users', userId), {
                        admin: false,
                        writer:false,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        mail: data.mail,
                        birthDate: data.birthDate 
                    });
                    return updateUserPromise.then(result => {
                        unsubscribe();
                        setLoading(false);
                        dispatch({type:"LOGIN", fullName:data.firstName + " " + data.lastName, id:userId})
                        navigate("/", {replace : true})
                    }).catch(error => console.error(error));
                }, (error) => {
                    // shit happened
                    unsubscribe();
                    console.error(error);
                    setLoading(false);
                });            
            })
            .catch((error) => {
                console.log(error)
            });            
        }
    }

    return (
        <article>
            <h2>Formulaire d'inscription</h2>
            <form className="form_basic" onSubmit={submit}>
                <div>
                    <label>Prénom : </label>
                    <input type="text" maxLength="63" required value={firstName} onChange={(e) => setfirstName(e.target.value)} />
                    <p>Votre prénom sera visible uniquement par votre ostéopathe.</p>
                </div>
                <div>
                    <label>Nom de famille : </label>
                    <input type="text" maxLength="63" required value={lastName} onChange={(e) => setlastName(e.target.value)} />
                    <p>Votre nom de famille sera visible uniquement par votre ostéopathe.</p>
                </div>
                <div>
                    <label>Mail : </label>
                    <input type="email" maxLength="255" required value={mail} onChange={(e) => setMail(e.target.value)} />
                    <p>Votre mail est utile pour s'assurer que votre compte est unique. Il sera utilisé pour vous identifier, contacter et être contacté par votre ostéopathe.</p>
                </div>
                <div>
                    <label>Date de naissance : </label>
                    <input type="date" required value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                    <p>Votre date de naissance sera visible uniquement par votre ostéopathe.</p>
                </div>
                <div>
                    <label>Mot de passe : </label>
                    <input type="password" maxLength="255" minLength="8" required value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <p>Votre mot de passe doit rester confidentiel, il est recommandé de faire un mot de passe long. Ici, il ne peut pas faire moins de 8 caractères.</p>
                </div>
                <input id="btn_register" className="register_submit" type="submit" value="Valider"/>
            </form>
        </article>
    )
}

export default Register