import { ReducerContext } from "./reducer/reducer.js"
import { useContext, useEffect, useState, Fragment } from "react"
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../App.js"
import { useNavigate } from "react-router-dom";

/* a page to modify a question, adding an answer to it and changing the "answer:true" */
const AnswerCreate = () => {
    const [state, dispatch] = useContext(ReducerContext)

    const [answerTitle, setAnswerTitle] = useState("");
    const [answerDescription, setAnswerDescription] = useState("");
    const [base, setBase] = useState({
        QT : "",
        QD : ""
    })

    const navigate = useNavigate();

    /* at he loading of the page, show the infos of the specific question */
    useEffect(() => {
        dispatch({type:"PAGE", number:0})

        getDoc(doc(db, "faq", state.questionId))
            .then((res)=> {
                setAnswerTitle(res.data().title)
                setAnswerDescription(res.data().description)

                setBase({
                    QT : res.data().title,
                    QD : res.data().description
                })
            });
    },[state.questionId, dispatch])

    /* take the infos from the form and put in in the answer */
    const submit = (e) => {
        e.preventDefault();

        const data = {
            answerTitle,
            answerDescription,    
        };

        const ref = doc(db, 'faq', state.questionId);
        setDoc(ref, { 
            answerTitle: data.answerTitle,
            answerDescription: data.answerDescription,
            answered: true
        }, { merge: true });

        navigate("/", {replace : true})
    }

    /* I use a conditionnal render to wait for the infos' loading */
    return (
        <article>        
            <h2>Création d'une réponse</h2>
            <h3>Voici la question : </h3>
            <div className="patient_faq">
                <div className="question_flex">                
                    <div className="question no_answer">
                        { state.faq[0] && (
                            <Fragment>
                                <h3>{base.QT}</h3>
                                <p>{base.QD}</p>
                            </Fragment>
                        )}
                    </div> 
                </div>  
            </div>
            <form onSubmit={submit}>
                <div className="answer_title">
                    <label>L'intitulé de la réponse : </label>
                    <input type="text" required value={answerTitle} onChange={(e) => setAnswerTitle(e.target.value)}></input>
                </div>
                <div>
                    <label>Le contenu de la réponse : </label>
                    <textarea spellCheck={false} rows="5" cols="30" required value={answerDescription} onChange={(e) => setAnswerDescription(e.target.value)}/>
                </div>
                <input className="answer_submit" type="submit" value="Valider"/>
            </form>
        </article>
    )
}

export default AnswerCreate