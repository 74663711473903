/* here are functions used somewhere else */

/* a function to change the size of the selected page's interlayer
call on each "page" to change the main interlayer (even when "none" is the right usage) */
export const interlayerSize = (number) => {
    let inter1 = document.getElementById('interlayer_1')
    let inter2 = document.getElementById('interlayer_2')
    let inter3 = document.getElementById('interlayer_3')

    /* base has normal style, special is bigger so the user know on which page he is*/
    let base = "font-size : 1.5rem; border : 2px solid var(--color_border); border-radius : 0"
    let special = "font-size : 2rem; border : 4px solid var(--color_border); border-radius : 15% 15% 0 0"
    
    if(number === 1) {
        inter1.style = special
        inter2.style = base
        inter3.style = base
        
    }else if(number === 2) {
        inter2.style = special
        inter1.style = base
        inter3.style = base

    }else if(number === 3) {
        inter3.style = special
        inter1.style = base
        inter2.style = base
        
    }else {
        inter1.style = base
        inter2.style = base
        inter3.style = base
    }
}

/* check the length of an input with a base of 255 characters, used for creating account */
export const inputLength = (input, length = 255) => {
    return input.length > length;
};

/* check if input is empty or spaces-only, used for creating account */
export const inputSpaces = (input, length = 0) => {
    return input.trim().length === length;
};

/* used to change the font size of the whole website, call in the navbar
used with a state to choose a "togglle effect" */
export const fontSize = (state) => {
    let policeSize = 1
    
    if(state === true){
        policeSize = 1.5
    }else if (state === false){
        policeSize = 1
    }
    document.body.style.fontSize = `${policeSize}rem`;
}

/* change the font of the website between two sets (used with a state to choose a "togglle effect") : 
- 2 choosen for the style (headers / the rest) 
- 1 for dyslexia (all text) */
export const fontFamily = (state) => {
    const root = document.querySelector(':root');
    
    if(state === true){
        root.style.setProperty('--font_family_headers', 'OpenDyslexic, serif');
        root.style.setProperty('--font_family_text', 'OpenDyslexic, serif');
    }else if(state === false){
        root.style.setProperty('--font_family_headers', 'Josefin Sans, sans-serif');
        root.style.setProperty('--font_family_text', 'Lobster, cursive');
    }
}