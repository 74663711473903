import React from "react"
import {initialState, reducer, ReducerContext} from "../components/reducer/reducer"

/* Provider make sure our Reducer can be used in all the website, very usefull ! */
const Provider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    
    return(
        <ReducerContext.Provider value={[state, dispatch]}>
            {children}
        </ReducerContext.Provider>
    )
}

export default Provider