import { ReducerContext } from "./reducer/reducer.js"
import { useContext, useEffect } from "react"

/* a Home page with usefull informations */
const Home = () => {
    const [, dispatch] = useContext(ReducerContext)

    useEffect(() => {
        dispatch({type:"PAGE", number:1})
    },[dispatch])

    return (
        <section>
            <article className="art_bio art_odd">
                <h2>Ma biographie</h2>
                <div className="art_div_flex">
                    <img className="home_photo" src="amelie-portrait.jpg" alt="profil de la praticienne" />
                    <div className="home_text">
                        <p>Née en 1996 à Roanne, Amélie Balandras a commencé par faire un BAC S avant de s’orienter dans son parcours médical.</p>
                        <p>Intéressée par l’aide à la personne depuis toute jeune, elle commença par des petits travaux d’été dans des EPHAD puis dans des services d’auxiliaire de vie à domicile.</p>
                        <p>Elle décida de devenir ostéopathe par vocation et s’orienta tout d’abord vers l’école de Saint Etienne (Centre International D’Ostéopathie) où elle passa cinq années avant de muter, pour sa dernière année, à Toulouse (Institut Toulousain d’Ostéopathie) dans un but autant pédagogique qu’humain.</p>
                        <p>C’est donc forte de ses expériences professionnelles qu'aujourd'hui elle vous propose son approche de l’ostéopathie.</p>
                    </div>
                </div>
            </article>
            <article className="art_bio art_even">
                <h2>Définition ostéopathie</h2>
                <div className="art_div_flex">
                    <img className="home_photo second_photo" src="amelie-travail.jpg" alt="praticienne s'exerçant au dessin anatomique" />
                    <div>
                        <p>Née de concepts créés par l'Américain Andrew Taylor Still, l'ostéopathie est une médecine manuelle douce.</p>
                        <p>"L’ostéopathe, dans une approche systémique, après diagnostic ostéopathique, effectue des mobilisations et des manipulations pour la prise en charge des dysfonctions ostéopathiques du corps humain.(1)"</p>
                        <p>Ainsi l'ostéopathie agit sur l'ensemble du corps et vise à établir un diagnostic permettant d'établir un plan de traitement en accord avec les attentes du patient.</p>
                        <p>Le traitement ostéopathique comprend de nombreuses techniques: articulaires, musculaires, faciales et viscérales pour ne citer qu'elles. Elles permettent de prendre en compte l'intégralité du corps comme un ensemble de structures et de fonctions dans le but de rééquilibrer le corps comme un tout. Ainsi l’ostéopathie vise à corriger et à maintenir le corps dans un état de santé stable tout en améliorant la qualité de vie de chacun.</p>
                        <p>(1)Arrêté du 12 décembre 2014 relatif à la formation en ostéopathie JORF n°0289 du 14 décembre 2014</p>
                    </div>
                </div>
            </article>
            <article className="art_bio art_odd">
                <h2>Domaines d'activités et particularités</h2>
                <div className="art_div_flex">
                    <img className="home_photo" src="manip.jpg" alt="praticienne travaillant sur un poignet" />
                    <div>
                        <p>Comme tout ostéopathe, Amélie Balandras vous propose une série de techniques visant à maintenir l’homéostasie de votre corps. Comprendre par là qu'elle vise à maintenir votre corps dans son meilleur fonctionnement. Le tout est bien évidemment adapté à la demande des personnes, de ce fait si un patient ne veut pas de craquement aucune technique de ce style ne sera effectuée.</p>
                        <p>Elle exerce ainsi pour des motifs de consultation suivants :</p>
                        <ul>
                            <li>Lumbago, cervicalgie, dorsalgie : technique de Trust (craquement)</li>
                            <li>Problème digestif, alimentation difficile, remontée acide : viscéral</li>
                            <li>Engourdissement, lourdeur du corps: technique faciale, drainage lymphatique et ouverture des différents point de compressions tout au long des trajets vasculaires</li>
                        </ul>
                        <p>Ce ne sont que quelques exemples d’application de l’ostéopathie bien sûr et chaque traitement est adapté à vous et à votre besoin du moment.</p>
                    </div>
                    <div>
                        <p>Les particularités d’Amélie Balandras se définissent grâce à ses stages et son expérience clinique et seront amenées à évoluer tout au long de ses formations pratiques :</p>
                        <ul>
                            <li>prise en charge de la petite enfance</li>
                            <li>prise en charge de la personne âgée</li>
                            <li>approche mandibulaire pour craquement de la mâchoire, suivi de l’installation d’un appareillage dentaire</li>
                            <li>blocage articulaire des pieds, mains, du bassin et du dos.</li>
                        </ul>
                    </div>
                </div>
            </article>
            <article className="art_bio art_even">
                <h2 style={{textAlign:"center"}}>Tarification et horraire</h2>
                <div>
                    <div>
                        <p style={{textAlign:"center"}}>Le cabinet d’ostéopathie est ouvert selon les horaires suivant : </p>
                        <table className="table_home">
                            <tbody>
                                <tr>
                                    <td className="td_txt">Jour</td>
                                    <td className="td_txt">Matinée</td>
                                    <td className="td_txt">Après-midi</td>
                                </tr>
                                <tr>
                                    <td className="td_txt">Lundi</td>
                                    <td>10h-13h</td>
                                    <td>14h-21h</td>
                                </tr>
                                <tr>
                                    <td className="td_txt">Mardi</td>
                                    <td>10h-13h</td>
                                    <td className="td_txt">Fermé</td>
                                </tr>
                                <tr>
                                    <td className="td_txt">Mercredi</td>
                                    <td className="td_txt">Fermé</td>
                                    <td>14h-19h</td>
                                </tr>
                                <tr>
                                    <td className="td_txt">Jeudi</td>
                                    <td>10h-13h</td>
                                    <td>14h-21h</td>
                                </tr>
                                <tr>
                                    <td className="td_txt">Vendredi</td>
                                    <td>10h-13h</td>
                                    <td>14h-18h</td>
                                </tr>
                                <tr>
                                    <td className="td_txt">Samedi</td>
                                    <td>10h-13h</td>
                                    <td>14h-19h</td>
                                </tr>
                            </tbody>
                        </table>                       
                        <p style={{textAlign:"center"}}>Une consultation ostéopathique dure en moyenne 45 minutes à 1 heure. Amélie Balandras vous propose des services à son cabinet de Cuinzier et à domicile sous les tarifications suivantes :</p>
                        <table className="table_home">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="td_txt">Adulte</td>
                                    <td className="td_txt">Mineur</td>
                                </tr>
                                <tr>
                                    <td className="td_txt">Domicile</td>
                                    <td>60€</td>
                                    <td>60€</td>
                                </tr>
                                <tr>
                                    <td className="td_txt">Cabinet</td>
                                    <td>50€</td>
                                    <td>45€</td>
                                </tr>
                                <tr>
                                    <td className="td_txt" colSpan="3" >Payement par chèques et espèces seulement</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="div_localisation">
                            <p>Le service à domicile se fait dans les 15km autour de Cuinzier.</p>
                            <img className="home_photo photo_cuinzier" src="alentours_cuinzier.png" alt="15km autour de Cuinzier"/>
                        </div>
                        <p style={{margin:"0.5rem 20%"}}>Un petit aménagement est à prévoir, Amélie Balandras dispose d’une table de pratique de 2m par 60cm nécessitant ainsi un peu de place pour permettre la pratique. N’hésitez pas à ménager un peu plus du double de la largeur (environ deux fois votre largeur d’épaule) afin de permettre une navigation plus simple autour de la table pour votre praticienne.</p>
                    </div>
                </div>
            </article>
        </section>
    )
}

export default Home

/* <p>Le cabinet d’ostéopathie est ouvert du lundi au vendredi de 9h à 21h sauf les mercredi après-midi et vendredi après-midi, réservé uniquement au domicile. Il est également ouvert le samedi de 10h à 18h. */

// style={{display: 'none'}}