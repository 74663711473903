import { useContext, useEffect, Fragment } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ReducerContext } from "../components/reducer/reducer.js";
import { strangerPath, userPath, adminPath } from '../router/router_front.jsx'

// Middleware is used to check if some url of the website are protected. If it's the case, it also check the reducer for the actual session's right
const Middleware = ({children}) => {
    const [state] = useContext(ReducerContext)
    const navigate = useNavigate();

    const location = useLocation()
    const currentPath = location.pathname

    // useEffect to check on the first load and at the change of url (no need for any action if the user has the right, so no 'else' needed)
    useEffect(() => {
        // need to NOT be logged in to access 
        if(strangerPath.includes(currentPath)){
            if(state.login){
                navigate('/')
            }
        }
        // need to be logged in to access
        if(userPath.includes(currentPath)){
            if(!state.login){
                navigate('/')
            }
        }
        // need to be an admin to access
        if(adminPath.includes(currentPath)){
            if(!state.admin){
                navigate('/')
            }
        }
    }, [currentPath, state.admin, state.login, navigate]);

    return(
        <Fragment>
            {children}
        </Fragment>
    )
}

export default Middleware