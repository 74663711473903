import { ReducerContext } from "./reducer/reducer.js";
import { useContext, Fragment } from 'react';

/* get the user private info (for admin) from a user id (only admin can load it) */
const UserToInfos = (userId) => {
    const [state] = useContext(ReducerContext);

    let value 
    /* if the id of the user from the mapping match the id in the UserToInfos, we got the user'data*/
    let array = state.users.map((e) => e.id === userId.userId && e )

    for(let i = 0; i<array.length; i++) {
        if(array[i] !== false) {          
            value = array[i]
        }
    }
    
    /* we return the different datas from the user */
    return (   
        <Fragment>
            { (value) ? (
                <h4>{value.firstName} {value.lastName}, né(e) le {value.birthDate} , {value.mail}</h4>
            ) : (
                <h4>erreur de chargement des données du user</h4>
            )}
        </Fragment>
    )
}

export default UserToInfos