import { useState } from 'react';
import { ReducerContext } from "./reducer/reducer.js"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { db } from "../App.js"
import { doc, getDoc } from "firebase/firestore";

/* a page to connect with an existing account */
const Connexion = () => {
    const [, dispatch] = useContext(ReducerContext) /* I can use "," for useless value : deconstructing*/
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({type:"PAGE", number:0})
    },[dispatch])

    const submit = (e) => {
        e.preventDefault();

        const data = {
            mail,
            password
        };

        const auth = getAuth();
        /* the sign in from firebase */
        signInWithEmailAndPassword(auth, data.mail, data.password)
        .then((userCredential) => {
            const user = userCredential.user;
            getDoc(doc(db, "users", user.uid))
            .then((res)=> {
                dispatch({type:"LOGIN", fullName:res.data().firstName + " " + res.data().lastName, id:user.uid})
                /* if user is an admin, we put it in the reducer */
                if (res.data().admin) {
                    dispatch({type:"ADMIN"})
                }
                navigate("/", {replace : true})
            })
            .catch((err) => {
                console.log(err)
            });
        })
        .catch((error) => {
            window.alert("Il semblerait que vos identifiants soient incorrect")
        });
    }

    function forgot() {
        if(mail === ""){
            window.alert('vous devez remplir le champ "Mail" pour recevoir le mail de réinitialisation de mot de passe')
        } else {      
            const auth = getAuth();
            sendPasswordResetEmail(auth, mail)
            .then(() => {
                window.alert("Un mail de changement de mot de passe a été envoyé à cette adresse mail : " + mail)
            })
            .catch((error) => {
                window.alert("Cette boîte mail n'a pas de comptes chez nous")
            });
        }
    }

    return (
        <article>
            <h2>Formulaire de connexion</h2>
            <form className="form_basic" onSubmit={submit}>
                <div className="form_connexion_field">
                    <label>Mail : </label>
                    <input type="mail" maxLength="255" required value={mail} onChange={(e) => setMail(e.target.value)} />
                </div>
                <div className="form_connexion_field">
                    <label>Mot de passe : </label>
                    <input type="password" maxLength="255" required value={password} onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <input className="connexion_submit" type="submit" value="Valider"/>
            </form>
            <button className="forgot_password" onClick={forgot} >Mot de passe oublié</button>
        </article>
    )
}

export default Connexion