import { Routes, Route } from "react-router-dom";

/** all the jsx components import for the Routeur **/
import Middleware from "../controllers/middleware.jsx"
import Home from "../components/Home.jsx";
import Faq from "../components/Faq.jsx"
import Contact from "../components/Contact.jsx"
import Register from "../components/Register.jsx"
import Connexion from "../components/Connexion.jsx"
import QuestionCreate from "../components/QuestionCreate.jsx"
import QuestionModify from "../components/QuestionModify.jsx"
import AnswerCreate from "../components/AnswerCreate.jsx"
import AnswerModify from "../components/AnswerModify.jsx"
import Error from "../components/Error.jsx"

/* path only accessible to user without any log */
export const strangerPath = [
    "/register",
    "/connexion"
];    

/* path only accessible to logged in user */
export const userPath = [
    "/question",
    "/question-modify"
];

/* path only accessible to admin */
export const adminPath = [
    "/answer",
    "/answer-modify"
];

export const Routeur = () => {
    /* return with some route/path and use the middleware for protected path */
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/register" element={<Middleware> <Register /> </Middleware>} />           
            <Route path="/connexion" element={<Middleware> <Connexion /> </Middleware>} /> 
            <Route path="/question" element={<Middleware> <QuestionCreate /> </Middleware>} /> 
            <Route path="/question-modify" element={<Middleware> <QuestionModify /> </Middleware>} />
            <Route path="/answer" element={<Middleware> <AnswerCreate /> </Middleware>} /> 
            <Route path="/answer-modify" element={<Middleware> <AnswerModify /> </Middleware>} />
            <Route path="/*" element={<Error />} />
        </Routes>
    );
};