import { ReducerContext } from "./reducer/reducer.js";
import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { doc, deleteDoc, query, where, setDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../App.js"

/* a template to show the faq's answers (to everyone) */
const AnswerTemplate = ({index, ID}) => {
    const [state, dispatch] = useContext(ReducerContext);
    const [canDelete, setCanDelete] = useState(false)

    const navigate = useNavigate();

    /* we stock this specific question's id in the reducer, then we navigate to the modify page and call this id */
    const answerModify = (e) => {
        dispatch({type:"QUESTION", id:ID})
        navigate("/answer-modify", {replace : true})
    }

    /* Delete has a safe net, it only work the second time. If it's the last question of this specific user, he pass writer:false */
    const setDelete = (e) => {
        if (!canDelete) {
            window.alert("Êtes-vous sûr de supprimer une question et sa réponse ? Si oui, re-cliquez sur le boutton de suppression")
            setCanDelete(!canDelete)
        } else {
            /* if it's the last question of the user, he become 'writer:false' */ 
            const q1 = query(collection(db, "faq"), where("user", "==", state.faq[index].user));
            getDocs(q1)
            .then((res)=> {
                
                let datas = []
                res.forEach((element) => datas.push(element) )

                if (datas.length <= 1) {                    
                    const ref = doc(db, 'users', state.faq[index].user);
                    setDoc(ref, { 
                        writer:false
                    }, { merge: true });
                }                             
            })
            /* delete the question */
            deleteDoc(doc(db, "faq", state.faq[index].id));
            setCanDelete(!canDelete)
            navigate("/", {replace : true})
        }
    }

    /* the answer always has "Balandras Amélie" */ 
    return (
        <div className="answer_flex">
            {state.admin &&
                <div className="answer_btns">
                    <button onClick={(e) => answerModify(e)}>Modifier</button>
                    <button onClick={(e) => setDelete(e)}>Supprimer l'ensemble</button>
                </div>
            }
            <div className="answer">
                <h4>Balandras Amélie</h4>
                <h3>{state.faq[index].answerTitle}</h3>
                <p>{state.faq[index].answerDescription}</p>
            </div>                    
        </div>
    )
}

export default AnswerTemplate