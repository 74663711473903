import { ReducerContext } from "./reducer/reducer.js";
import { useContext, useEffect, useState, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../App.js"

/* -admion only- a page to modify an answer, so a question already answered */
const AnswerModify = () => {
    const [state, dispatch] = useContext(ReducerContext)

    const [answerTitle, setAnswerTitle] = useState("");
    const [answerDescription, setAnswerDescription] = useState("");
    const [base, setBase] = useState({
        QT : "",
        QD : "",
        AT : "",
        AD : ""
    })

    const navigate = useNavigate();

    useEffect (() => {
        dispatch({type:"PAGE", number:0})

        getDoc(doc(db, "faq", state.questionId))
            .then((res)=> {
                setAnswerTitle(res.data().answerTitle)
                setAnswerDescription(res.data().answerDescription)

                setBase({
                    QT : res.data().title,
                    QD : res.data().description,
                    AT : res.data().answerTitle,
                    AD : res.data().answerDescription
                })
            });        
    },[state.questionId, dispatch])

    const submit = (e) => {
        e.preventDefault();

        const data = {
            answerTitle,
            answerDescription,    
        };

        const ref = doc(db, 'faq', state.questionId);
        setDoc(ref, { 
            answerTitle: data.answerTitle,
            answerDescription: data.answerDescription 
        }, { merge: true });

        navigate("/", {replace : true})
    }

    return (
        <article>        
            <h2>Modification d'une réponse</h2>
            <p>Ici, vous pouvez modifier votre réponse pour la Foire aux Questions. Elle sera visible par tous et vérouille la possibilité au patient de modifier sa question.</p>
            <h3>Voici l'ensemble question-réponse avant modification : </h3>
            <article className="art_faq">
                <div className="patient_faq">
                    <div className="question_flex">                
                        <div className="question">
                            { state.faq[0] && (
                                <Fragment>
                                    <h3>{base.QT}</h3>
                                    <p>{base.QD}</p>
                                </Fragment>
                            )}
                        </div> 
                    </div>  
                </div>
                <div className="answer_flex">                                
                    <div className="answer">
                        <h4>Balandras Amélie</h4>
                        { state.faq[0] && (
                            <Fragment>
                                <h3>{base.AT}</h3>
                                <p>{base.AD}</p>
                            </Fragment>
                        )}
                    </div>                    
                </div>
            </article>
            <h3>Voici le formulaire pour votre version modifiée de votre réponse. Si au final vous ne voulez rien changer, il vous suffit de retourner sur le site via les onglets.</h3>
            <form onSubmit={submit}>
                <div className="question_title">
                    <label>L'intitulé de votre réponse : </label>
                    <input type="text" required value={answerTitle} onChange={(e) => setAnswerTitle(e.target.value)}></input>
                </div>
                <div>
                    <label>Le contenu de votre réponse : </label>
                    <textarea spellCheck={false} rows="5" cols="30" required value={answerDescription} onChange={(e) => setAnswerDescription(e.target.value)}/>
                </div>
                <input className="question_submit" type="submit" value="Valider"/>
            </form>
        </article>
    )
}

export default AnswerModify