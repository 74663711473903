import { ReducerContext } from "./reducer/reducer.js"
import { useContext, useEffect } from "react"
import { NavLink } from "react-router-dom";
import { db } from "../App.js"
import { collection, getDocs, query, where } from "firebase/firestore"; 
import QuestionTemplate from "./QuestionTemplate.jsx";
import AnswerTemplate from "./AnswerTemplate.jsx";

/* the Faq page contain questions from the users and answers from the admin */
const Faq = () => {
    const [state, dispatch] = useContext(ReducerContext)

    /* a useEffect to load the needed datas */
    useEffect(() => {
        dispatch({type:"PAGE", number:2})

        /** we get the datas from the "faq" database depending from who is connected **/
        /* the admin's path */
        if(state.admin){
            getDocs(collection(db, "faq"))
            .then((res)=> {
                let datas = []
                res.forEach((element) => datas.push(element) )

                dispatch({type:"FAQ", faq:
                    [...datas.map((doc) => {
                        return {id:doc.id, ...doc.data()}
                        
                        })
                    ]
                })
            })

            /* get all the users whom have created questions */
            const q = query(collection(db, "users"), where("writer", "==", true));
            getDocs(q)
            .then((res)=> {                
                let datas = []
                res.forEach((element) => datas.push(element) )

                dispatch({type:"USERS", users:
                    [...datas.map((doc) => {
                        return {id:doc.id, ...doc.data()}
                        
                        })
                    ]
                })
            })
        }
        /* the non-logged in user's path, only got the answered questions */
        else if(!state.login) {
            const q = query(collection(db, "faq"), where("answered", "==", true));            
            getDocs(q)
            .then((res)=> {
                let datas = []
                res.forEach((element) => datas.push(element) )

                dispatch({type:"FAQ", faq:
                    [...datas.map((doc) => {
                        return {id:doc.id, ...doc.data()}
                        
                        })
                    ]
                })
            })
        }
        /* the logged in user's path, got the answered questions and his/her own questions */
        else if(state.login) {
            console.log("est connecté, je suis :")
            console.log(state.fullName)
            const q1 = query(collection(db, "faq"), where("answered", "==", false), where("user", "==", state.userId)); 
            const q2 = query(collection(db, "faq"), where("answered", "==", true))

            let datas = []

            getDocs(q1)
            .then((res)=> {                
                res.forEach((element) => datas.push(element) )

                getDocs(q2)
                .then((res2)=> {
                    res2.forEach((el) => datas.push(el) )

                    dispatch({type:"FAQ", faq:
                        [...datas.map((doc) => {
                            return {id:doc.id, ...doc.data()}
                            
                            })
                        ]
                    })
                })
            })
        }
    },[dispatch, state.admin, state.fullName, state.login, state.userId])

    return (
        <section className="faq">
            <article>
                <h4>Bienvenue sur la page de foire aux questions.</h4>
                <p>Vous trouverez ici l'espace où votre ostéopathe est là pour répondre à toutes vos interrogations.</p>
                <p>Vos questions, anonymisées, seront visibles par tous les visiteurs de ce site une fois que votre praticienne y aura répondu. Tant que vos questions n'ont pas eu de réponses, elles apparaîtront dans un fond rouge.</p>
                <p>Votre identité reste uniquement accessible à votre praticienne.</p>
                <p>Elle se garde la possibilité de vous répondre directement par mail si la limite du secret professionnel est franchie.</p>
            </article>
            { (state.login) &&
                <div className="navlink_question">
                    <NavLink  to="/question">Écrire une question</NavLink>
                </div>
            }
            { state.faq && state.faq.map((e,i) => 
                <article className="art_faq" key={i}>
                    <QuestionTemplate key={"q"+i} index={i} ID={e.id} /> 
                    { e.answered && 
                        <AnswerTemplate key={"a"+i} index={i} ID={e.id} />
                    }
                </article>
            ) }
        </section>
    )
}

export default Faq