import { ReducerContext } from "./reducer/reducer.js"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { db } from "../App.js"
import { setDoc, doc, collection, addDoc } from "firebase/firestore"; 

/* a page to create a new question */
const QuestionCreate = () => {
    const [state, dispatch] = useContext(ReducerContext)

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        dispatch({type:"PAGE", number:0})
    },[dispatch])

    const submit = (e) => {
        e.preventDefault();

        const data = {
            title,
            description  
        };

        /* create a new doc, random question's id, link the the user's id*/
        addDoc(collection(db, "faq"), {
            answered:false,
            title:data.title,
            description:data.description,
            user:state.userId
        })

        /* the user got writer:true, so the admin will have his/her infos */
        const ref = doc(db, 'users', state.userId);
        setDoc(ref, { 
            writer:true
        }, { merge: true });

        navigate("/faq", {replace : true})
    }

    return (
        <article>        
            <h2>Création d'une question</h2>
            <p>Ici, vous pouvez créer une question pour la Foire aux Questions. Elle sera visible uniquement par vous et l'ostéopathe tant que cette dernière n'y aura pas répondue.</p>
            <form onSubmit={submit}>
                <div className="question_title">
                    <label>L'intitulé de votre question : </label>
                    <input type="text" required value={title} onChange={(e) => setTitle(e.target.value)}></input>
                </div>
                <div>
                    <label>Le contenu de la question : </label>
                    <textarea rows="5" cols="30" required value={description} onChange={(e) => setDescription(e.target.value)}/>
                </div>
                <input className="question_submit" type="submit" value="Valider"/>
            </form>
        </article>
    )
}

export default QuestionCreate