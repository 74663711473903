import { ReducerContext } from "./reducer/reducer.js";
import { useContext, Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { db } from "../App.js"
import UserToInfos from "./UserToInfos.jsx";
import { collection, getDocs, query, where, setDoc, doc, deleteDoc } from "firebase/firestore"; 

/* a template for the question, used in faq */
const QuestionTemplate = ({index, ID}) => {
    const [state, dispatch] = useContext(ReducerContext);
    const [canDelete, setCanDelete] = useState(false)

    const navigate = useNavigate();

    /* redirect to the answer with the question's id in the reducer -admin only (render)- */
    const answerRedirect = (e) => {
        dispatch({type:"QUESTION", id:ID})
        navigate("/answer", {replace : true})
    }

    /* redirect to the question modify with the question's id (check render for more details) */
    const questionModify = (e) => {
        dispatch({type:"QUESTION", id:ID})
        navigate("/question-modify", {replace : true})
    }

    /* delete the question, by the question's user or by the admin */
    const deleteQuestion = (e) => {
        if (!canDelete) {
            window.alert("Êtes-vous sûr de supprimer une question ? Si oui, re-cliquez sur le boutton de suppression")
            setCanDelete(!canDelete)
        } else {
            /* if it's the last question of the user, he become 'writer:false' */ 
            const q1 = query(collection(db, "faq"), where("user", "==", state.faq[index].user));
            getDocs(q1)
            .then((res)=> {
                
                let datas = []
                res.forEach((element) => datas.push(element) )

                if (datas.length === 0) {                    
                    const ref = doc(db, 'users', state.faq[index].user);
                    setDoc(ref, { 
                        writer:false
                    }, { merge: true });
                    console.log("on check combien de questions il reste")
                }                             
            })
            /* delete the question */
            console.log("on delete la question")
            deleteDoc(doc(db, "faq", state.faq[index].id));
            setCanDelete(!canDelete)
            // navigate("/question", {replace : true})
        }
    }

    /* use of conditionnal render to wait for loading and show/hide specific buttons */
    return (    
        <div className="patient_faq">
            {state.users &&
                <div className="privateInfos">                    
                    <UserToInfos userId={state.faq[index].user} />
                </div>
            }
            <div className="question_flex">
                {state.faq[index].answered ? (
                    <div className="question">
                        <h3>{state.faq[index].title}</h3>
                        <p>{state.faq[index].description}</p>
                    </div> 
                ) : (
                    <Fragment>
                        <div className="question no_answer">
                            <h3>{state.faq[index].title}</h3>
                            <p>{state.faq[index].description}</p>
                        </div> 
                        {(state.admin === true) ? (
                            <div className="question_btns">
                                <button onClick={(e) => answerRedirect(e)}>Répondre</button>
                                <button onClick={(e) => deleteQuestion(e)}>Supprimer</button>
                            </div>
                        ) : (
                            <div className="question_btns">
                                <button onClick={(e) => questionModify(e)}>Modifer</button>
                                <button onClick={(e) => deleteQuestion(e)}>Supprimer</button>
                            </div>
                        )}
                    </Fragment>
                )}                               
            </div>            
        </div>
    )
}

export default QuestionTemplate