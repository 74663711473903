import { useEffect, useContext } from "react"
import { ReducerContext } from "./reducer/reducer.js";

/* a custom page for error (404 for exemple) */
const Error = () => {

    const [, dispatch] = useContext(ReducerContext)

    useEffect(() => {
        dispatch({type:"PAGE", number:0})
    },[dispatch])
    
    return (
        <p>Vous êtes sur une page d'erreur, peut être un lien mal recopié ou dysfonctionnel ? Vous pouvez retourner sur le site via les bouttons juste au dessus.</p>
    )
}

export default Error