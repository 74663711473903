import { NavLink } from "react-router-dom";
import { ReducerContext } from "./reducer/reducer.js"
import { useContext, Fragment, useEffect, useState } from "react"
import { interlayerSize, fontSize, fontFamily } from "./functions/functions.js"
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

/* the Navbar, always present on top of the screen */
const Nav = () => {
    const [state, dispatch] = useContext(ReducerContext)

    const [size, setSize] = useState(true)
    const [police, setPolice] = useState(true)
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;

    function deconnexion() {
        signOut(auth)
        .then(() => {
            dispatch({type:'LOGOUT'})
        })
        .catch((error) => {
            console.log(error)
        });
        navigate("/", {replace : true})
    }

    /* show wich interlayer is the main and refresh when the page's number change */
    useEffect(() => {
       interlayerSize(state.pageNumber)
    },[state.pageNumber])

    /* some conditionnal render :
    - if user exist, it mean user is logged in, so we show his full name and the deconnexion button
    - else, we show the register and connexion button 
    And two buttons, both are toggle, one for the globzal font size and the other for the police font */
    return (
        <nav>
            <div className="nav_btns">
                {  (user) ? ( 
                    <Fragment>                       
                        { (state.fullName) ? (
                            <p>{state.fullName}</p>
                        ) : (
                            <p>Il y a eu une erreur, veuillez vous déconnecter</p>    
                        )} 
                        <p className="dash" >/</p>
                        <button className="btn_deconnexion" onClick={deconnexion}> Se déconnecter</button>
                    </Fragment>
                ) : (
                    <Fragment>                         
                        <NavLink  to="/register">S'inscrire</NavLink>                        
                        <p>/</p>
                        <NavLink  to="/connexion">Se connecter</NavLink>
                    </Fragment>
                 )} 
            </div>
            <div className="access_btns">
                    <button className="adaptating" onClick={() => { fontSize(size);setSize(!size) }}>changer la taille de la police</button>
                    <button className="adaptating" onClick={() => { fontFamily(police);setPolice(!police) }}>police dyslexique</button>
            </div>
            <ul>
                <li id="interlayer_1">
                    <NavLink  to="/">Accueil</NavLink>
                </li>
                <li id="interlayer_2">
                    <NavLink  to="/faq">Foire aux Questions</NavLink>
                </li>
                <li id="interlayer_3">
                    <NavLink  to="/contact">Contact et Rendez-vous</NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default Nav