import { ReducerContext } from "./reducer/reducer.js";
import { useContext, useEffect, useState, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../App.js"

/* a page to modify a question */
const QuestionModify = () => {
    /*!! faire une vérif que la personne connecté a le droit d'accéder à cette page !!*/

    const [state, dispatch] = useContext(ReducerContext)

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [base, setBase] = useState({
        QT : "",
        QD : ""
    })

    const navigate = useNavigate();

    useEffect(() => {
        dispatch({type:"PAGE", number:0})

        /* get the specific question */
        getDoc(doc(db, "faq", state.questionId))
            .then((res)=> {
                setTitle(res.data().title)
                setDescription(res.data().description)

                setBase({
                    QT : res.data().title,
                    QD : res.data().description
                })
            });
    },[state.questionId, dispatch])

    /* modify the question with the new datas */
    const submit = (e) => {
        e.preventDefault();

        const data = {
            title,
            description,    
        };

        const ref = doc(db, 'faq', state.questionId);
        setDoc(ref, { 
            title: data.title,
            description: data.description 
        }, { merge: true });

        navigate("/", {replace : true})
    }

    /* I use a conditionnal render to make sure the question has the time to load */
    return (
        <article>        
            <h2>Modification d'une question</h2>
            <p>Ici, vous pouvez modifier votre question pour la Foire aux Questions. Elle sera visible uniquement par vous et l'ostéopathe tant que cette dernière n'y aura pas répondue.</p>
            <h3>Voici votre question avant modification : </h3>
            <div className="patient_faq">
                <div className="question_flex">                
                    <div className="question no_answer">
                        { state.faq[0] && (
                            <Fragment>
                                <h3>{base.QT}</h3>
                                <p>{base.QD}</p>
                            </Fragment>
                        ) }
                    </div> 
                </div>  
            </div>
            <h3>Voici le formulaire pour votre version modifiée de votre question. Si au final vous ne voulez rien changer, il vous suffit de retourner sur le site via les onglets.</h3>
            <form onSubmit={submit}>
                <div className="question_title">
                    <label>L'intitulé de votre question : </label>
                    <input type="text" required value={title} onChange={(e) => setTitle(e.target.value)}></input>
                </div>
                <div>
                    <label>Le contenu de la question : </label>
                    <textarea spellCheck={false} rows="5" cols="30" required value={description} onChange={(e) => setDescription(e.target.value)}/>
                </div>
                <input className="question_submit" type="submit" value="Valider"/>
            </form>
        </article>
    )
}

export default QuestionModify