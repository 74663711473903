import './App.css';
import { BrowserRouter } from "react-router-dom";
import { Routeur } from "./router/router_front.jsx";
import Nav from "./components/Nav.jsx";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCM86II_vYQDO7yurIXzjm-SEnZ22iRYW0",
  authDomain: "balandras-amelie-osteopathe.firebaseapp.com",
  projectId: "balandras-amelie-osteopathe",
  storageBucket: "balandras-amelie-osteopathe.appspot.com",
  messagingSenderId: "713263166949",
  appId: "1:713263166949:web:3805f3bcc926734c216b0c",
  measurementId: "G-VRWCPV3ENV"
};

// initialise firestore
const app = initializeApp(firebaseConfig);
// initialise cloud firestore
export const db = getFirestore(app);

/* the App will return all we need to the main index. It contain :
- the BrowserRouter (work with the Routeur to propose the different access to each pages)
- the Nav (navbar for easy acces for the user experience, always at the top of a page)
- the Routeur (work in pair with the BrowserRouteur) */
function App() {

  return (
    <BrowserRouter>      
      <Nav />
      <Routeur />     
    </BrowserRouter>
  );
}

export default App;