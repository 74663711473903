import { ReducerContext } from "./reducer/reducer.js"
import { useContext, useEffect } from "react"
import { IconPhone } from "./fonts/icons.jsx"

/* contain details about the professional and how to contact & find her */
const Contact = () => {
    const [, dispatch] = useContext(ReducerContext)

    useEffect(() => {
        dispatch({type:"PAGE", number:3})
    },[dispatch])

    /* the different part of the osteo's mail */
    let aa = "mai"
    let ab = "lto:"
    let a = "oam"
    let b = "elie"
    let c = ".baland"
    let d = "ras@"
    let e = "gma"
    let f = "il.com"
    let adresse = aa+ab+a+b+c+d+e+f

    return (
        <section>
            <h4>Adresse du cabinet : 608 route de Belviré 42460 Cuinzier</h4>
            <iframe title="the map" className="mappy" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5530.428536115252!2d4.2718835!3d46.1265616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f475603ea04c1f%3A0x13d04bf0d76360ce!2sDeclas%20Marie%20Veronique!5e0!3m2!1sfr!2sfr!4v1668596054474!5m2!1sfr!2sfr" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            <div className="contact_infos">
                <a href="tel:+640366927">
                    <IconPhone />   
                    06 40 36 69 27             
                </a>
                <div>                
                    <a href="https://www.doctolib.fr/osteopathe/cuinzier/amelie-balandras/booking?utm_campaign=website-button&amp;utm_source=amelie-balandras-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=amelie-balandras" target="_blank" rel="noreferrer">Mon lien de prise de rendez-vous en ligne <img style={{height:"1rem"}} src="https://pro.doctolib.fr/external_button/doctolib-blue-transparent.png" alt="Doctolib"/></a>
                </div>
                <a href={adresse} >M'envoyer un mail</a>
            </div>
        </section>
    )
}

export default Contact