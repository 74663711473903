import React from "react"

/* initialState (used as base for the reducer) with the different value usefull for the whole website
- login, admin and userId are used to check the info of the user between different url
- fullName is used for a simple render
- pageNumber is used for the interlayer render
- faq load all the questions/answers the current user has the right to see
- users load - admin only - the list of users who wrote questions 
- questionId is used when a question is modify/delete/answered */
export const initialState = {
    login:false,
    admin:false,
    fullName:"",
    userId:"",
    pageNumber:1,
    faq:"",
    users:"",
    questionId:"",
};

/* reducer with numerous cases */
export const reducer = (state, action) => {
    switch(action.type){
        /* user is logged in, his fullName and Id are retrieve for later uses (home page and faq) */
        case 'LOGIN' :
            return {...state, login:true, fullName:action.fullName, userId:action.id}
        /* user is an admin, she sees specific private information, like in faq page */
        case 'ADMIN' :
            return {...state, admin:true}
        /* user log out, return to initialState */
        case 'LOGOUT' :
            return state = initialState
        /* get the page's number to modify the interlayer */
        case 'PAGE' :
            return {...state, pageNumber:action.number}
        /* load the faq, depending the current user's autorization */
        case 'FAQ':
            return {...state, faq:action.faq}
        /* load the users who wrote at least one question - admin only - */
        case 'USERS' :
            return {...state, users:action.users}
        /* get a specific question's ID to redirect to a modify page with the correct id (question and answer) */
        case 'QUESTION' :
            return {...state, questionId:action.id}
        default: return state 
    }
}

export const ReducerContext = React.createContext([])